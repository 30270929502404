import React, { useContext, useEffect, useState } from "react"
import loadable from "@loadable/component"
import { default as Logo } from "../images/storm.svg"
import { Link } from "gatsby"

import GoodsSearch from "./goods/GoodsSearch"
import { BsSearch } from "react-icons/bs"
import { PageContext } from "../context/context"

import { ChevronRightIcon } from "@heroicons/react/solid"
import { MiniCart } from "./cart/miniCart"
import { UserMenu } from "./elements/UserMenu"
import MainMenu from "./layout/MainMenu"

// const MiniCart = loadable(() => import("./cart/miniCart"), {
//   resolveComponent: components => components.MiniCart,
// })
// const UserMenu = loadable(() => import("./elements/UserMenu"), {
//   resolveComponent: components => components.UserMenu,
// })

// const MainMenu = loadable(() => import("./layout/MainMenu"))

export default function Header({ cat, white, location, data, pageContext }) {
  const [open, setOpen] = useState(false)
  const [context, dispatch] = useContext(PageContext)

  const [position, setPosition] = useState()
  const [visible, setVisible] = useState(true)
  const [displaySearch] = useState(false)
  const [clickSearch, setClickSearch] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      let moving = window.scrollY

      setVisible(position > moving)
      setPosition(moving)
    }
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  const cls = visible ? "-top-1" : "-top-44"

  let menu = []
  if (context?.menu) {
    menu = context?.menu
  }

  useEffect(() => {
    setPosition(window.scrollY)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    setClickSearch(false)
    // eslint-disable-next-line
  }, [location])

  return (
    <>
      <div className="bg-gradient-to-r from-indigo-950 bg-indigo-800 text-white p-1 py-2 h-10    text-sm  ">
        <Link
          to={"/doprava-zdarma"}
          className="max-w-7xl px-3 mx-auto flex items-center space-x-4 justify-center shadow-inner"
        >
          <span>Doprava zdarma při nákupu nad 3 000 Kč</span>
          <ChevronRightIcon className="w-4 h-4" />
        </Link>
      </div>
      <div
        className={
          "shadow-sm sticky   w-full z-40  -mb-20 transition-all ease-in-out delay-100 duration-500    " +
          cls
        }
      >
        <div
          className={
            "    z-50 w-full top-0 border-b backdrop-filter   backdrop-blur-md bg-white bg-opacity-50    "
          }
        >
          <div className="mx-auto px-4   flex w-full  h-16">
            <div className="xl:px-8 flex justify-end xl:flex xl:justify-start  items-center  w-full ">
              <div className="flex items-center justify-start  xl:flex-1 xl:w-24   ">
                <Link to={"/"} className="w-24 absolute left-6 top-2 z-50 ">
                  <span className="sr-only">STORM</span>
                  <img src={Logo} className="w-24  " alt="Logo" />
                </Link>
              </div>
              <div
                className={
                  "  hidden xl:flex  xl:order-3  -mx-4    top-16 xl:top-0  xl:mt-0  bg-indigo-900 xl:bg-transparent transition-all duration-150   ease-in-out    xl:opacity-100 xl:h-auto "
                }
              >
                <div className=" m-3 w-full">
                  <GoodsSearch location={location} />
                </div>
              </div>
              <div className="xl:order-3 col-span-3 ">
                <div className="xl:order-2 flex  items-center justify-end xl:flex-1 xl:ml-20 space-x-2">
                  <>
                    <div
                      className={
                        " cursor-pointer rounded-full p-2 relative justify-center items-center flex xl:hidden transition-all duration-150   ease-in-out   text-gray-500   " +
                        (clickSearch
                          ? "bg-white text-gray-500 h-10  w-10 "
                          : displaySearch
                          ? "opacity-0 h-0 w-0"
                          : "opacity-100  h-10  w-10 ")
                      }
                    >
                      <BsSearch
                        className="w-5 h-5 "
                        onClick={() => setClickSearch(!clickSearch)}
                      />
                    </div>
                    <div className="w-12">
                      {/* <UserMenu white={white} /> */}
                    </div>

                    <div className=" w-12">
                      <MiniCart white={white} />
                    </div>
                  </>
                </div>
              </div>
              <div className=" xl:order-2   xl:w-[80%]  flex justify-end xl:justify-center py-3 ">
                <div className={"xl:order-2 flex items-center   h-full "}>
                  <MainMenu
                    open={open}
                    setOpen={setOpen}
                    menu={menu}
                    white={white}
                    location={location}
                    data={data}
                    pageContext={pageContext}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={
              "   xl:hidden w-full flex order-0 xl:order-3   xl:top-0    bg-indigo-900 xl:bg-transparent transition-all duration-150   ease-in-out    xl:opacity-100 xl:h-auto " +
              (displaySearch || clickSearch
                ? "  translate-y-0  h-16 opacity-100 "
                : "   h-0 overflow-hidden opacity-0  ")
            }
          >
            <div className=" m-3  w-full">
              <GoodsSearch location={location} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
