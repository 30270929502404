import React, { Fragment, useContext, useEffect, useState } from "react"
import { Transition, Dialog } from "@headlessui/react"
import { PageContext } from "../../context/context"

export const ModalLayout = props => {
  const fullscreen = props.fullscreen

  return (
    <>
      <div className="  rounded-b-lg w-full bg-white  ">
        {!fullscreen && (
          <div className="  p-5 lg:p-8 bg-gradient-to-r from-indigo-950 bg-indigo-800 text-white border-b  rounded-t-lg ">
            <div className=" lg:grid lg:grid-cols-2 items-center justify-between flex-wrap sm:flex-nowrap">
              <div className="  flex-shrink-0 ">
                <h3 className="text-lg lg:text-2xl leading-6 font-medium  ">
                  {props.title}
                </h3>
              </div>
            </div>
          </div>
        )}

        <div className=" p-5 lg:p-8 align-bottom rounded-b-lg  text-left transform transition-all  sm:align-middle sm:w-full  bg-white ">
          {props.children}
        </div>
      </div>
    </>
  )
}

export const Modal = props => {
  const [state, dispatch] = useContext(PageContext)
  const [open, setModalIsOpen] = useState(false)

  let Component = state?.modal?.component
  let dynamicProps = state?.modal?.params

  useEffect(
    () => {
      state?.modal && setModalIsOpen(state?.modal?.open)
    },
    // eslint-disable-next-line
    [state]
  )

  function close() {
    setModalIsOpen(false)
    setTimeout(() => {
      dispatch({
        type: "CLEAR_MODAL",
      })
    }, 600)
  }

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white  z-[1000]  text-left shadow-xl transition-all  sm:w-full sm:max-w-5xl mx-3  ">
                  <div className=" align-bottom  text-left  transform transition-all overflow-hidden sm:align-middle sm:w-full    rounded-lg lg:mb-10 ">
                    <div
                      role="button"
                      tabIndex={0}
                      className="absolute top-3 right-3 lg:top-6 lg:right-6 mx-auto  z-50"
                      onClick={() => close()}
                      onKeyUp={() => close()}
                    >
                      <button className="ml-1 flex items-center justify-center h-10 w-10 rounded-full bg-white  focus:outline-none  hover:bg-gray-200   text-gray-500  ">
                        <span className="sr-only">Close sidebar</span>

                        <svg
                          className="h-6 w-6 "
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                    {Component && <Component {...dynamicProps} {...props} />}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}
